import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Container } from "@material-ui/core"
import { breakpoints } from "../components/variables"
import FormSubmitter from "../components/form-submitter"
import PageTitle from "../components/page-title"
import contactMail from "../images/contact-mail.svg"

const RequestDemoW = styled.div``
const RequestDemoIW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50vh;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
  }
`
const TextW = styled.div`
  width: 47%;
  font-family: "Montserrat-Medium", sans-serif;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`
const Title = styled.h1`
  font-size: 3rem;
  font-weight: initial;
  margin-top: 0;
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 2rem;
  }
`
const Text = styled.h6`
  font-size: 1.5rem;
  font-weight: initial;
  margin: 0;
  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;
  }
`

const ContactUs = () => (
  <Layout>
    <SEO title="Contact Us" />
    <PageTitle
      title="Contact Us"
      subTitle="sales@datamap.tools"
      icon={contactMail}
    />
    <RequestDemoW>
      <Container>
        <RequestDemoIW>
          <FormSubmitter referer="contact-us" />
          <TextW>
            <Title>
              Connecting Sage to cloud solutions with <b>DataMap</b>
            </Title>
            <Text>
              Integrate everything to Sage Software. From web carts, to business
              applications, to your vendor’s system, enable your cloud connected
              Sage Software system to do more for you.
            </Text>
          </TextW>
        </RequestDemoIW>
      </Container>
    </RequestDemoW>
  </Layout>
)

export default ContactUs
